import { css, cx } from "@emotion/css";
import Media from "../../components/Media/Media";

interface KudosPreviewProps {
  src?: string;
  isVideo?: boolean;
  className?: string;
  controls?: boolean;
  priority?: boolean;
}

const KudosPreview = ({
  src,
  isVideo,
  className,
  controls,
  priority,
}: KudosPreviewProps) => {
  return (
    <>
      <figure
        className={cx(
          className,
          "overflow-hidden mx-auto max-w-[400px] max-h-[400px] rounded-sm shadow-media aspect-square bg-white h-[280px] w-[280px] sm:w-[400px] sm:h-[400px]",
        )}
      >
        <Media
          className={cx(mediaStyle)}
          src={src ?? ""}
          isVideo={isVideo}
          controls={controls}
          priority={priority}
        />
      </figure>
    </>
  );
};

const mediaStyle = css`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
`;

export default KudosPreview;
