import { css } from "@emotion/css";
import type { NextPage } from "next";
import LandingPage from "../views/Landing/LandingPage";

const Home: NextPage = () => {
  return (
    <div className={landingPageStyle}>
      <LandingPage></LandingPage>
    </div>
  );
};

const landingPageStyle = css`
  .addrContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    font-family: "Inter", sans-serif;
  }

  .addr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.94175px 9.94175px 9.94175px 19.8835px;

    width: 640px;
    height: 49.71px;

    /* Light Grey */
    background: #f4f4f4;
    border-radius: 124.272px;
    transform: rotate(-11.04deg);
    position: absolute;
    top: 35px;
    left: 89px;
  }

  .addr span {
    font-style: normal;
    font-weight: 400;
    font-size: 21.1262px;
    line-height: 27px;

    color: #1a1a1a;
  }

  .frame2 {
    display: flex;
    gap: 5px;
    transform: rotate(-11.04deg);
    position: absolute;
    top: 100px;
    left: 185px;
  }

  .frame2 div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.94175px 9.94175px 9.94175px 19.8835px;
    width: 213.75px;
    height: 49.71px;
    background: #f4f4f4;
    border-radius: 124.272px;
  }

  .thespan {
    font-style: normal;
    font-weight: 400;
    font-size: 21.1262px;
    line-height: 27px;
    font-family: "Inter", sans-serif;

    /* identical to box height */

    /* Primary Text */
    color: #1a1a1a;
  }

  .frame3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9.94175px 9.94175px 9.94175px 19.8835px;

    width: 178.95px;
    height: 49.71px;
    /* Light Grey */
    background: #f4f4f4;
    border-radius: 124.272px;
    transform: rotate(-11.04deg);
    position: absolute;
    bottom: 66px;
    right: -35px;
  }

  .frame3 span {
    font-style: normal;
    font-weight: 400;
    font-size: 21.1262px;
    line-height: 27px;
    color: #1a1a1a;
  }

  /* CARD2 */
  .card2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    font-family: "Inter", sans-serif;
  }

  .card2con {
    position: absolute;
    left: 111px;
  }
  .seed {
    width: 728.09px;
    height: 58.25px;
    display: flex;
    justify-content: flex-start;
    padding-left: 21.83px;
    align-items: center;
    background: #f4f4f4;
    border-radius: 17.9222px;
    margin-bottom: 13.14px;
  }

  .seed span {
    font-style: normal;
    font-weight: 400;
    font-size: 19.0423px;
    line-height: 36px;

    color: #1a1a1a;
  }

  .seed2 {
    width: 728.09px;
    height: 89.61px;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: flex-start;
    padding-left: 21.24px;
    align-items: center;
    /* Light Grey */
    background: #f4f4f4;
    border-radius: 17.9222px;
    /* position: absolute;
  left: 111px; */
  }

  .seed2 span {
    font-style: normal;
    font-weight: 400;
    font-size: 19.0423px;
    line-height: 29px;
    text-align: start;
    color: #1a1a1a;
  }

  .card3 {
    position: relative;
    font-family: "Inter", sans-serif;
  }

  .topSec {
    width: 100%;
    height: 162.32px;

    background-image: url("/images/landing/bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .fullnft {
    display: none;
  }

  .bottomSec {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -25px;
  }

  .bottomText {
    width: 343.45px;
    font-style: normal;
    font-weight: 700;
    font-size: 19.0278px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 10px;
    /* Primary Text */
    color: #1a1a1a;
  }
  @media (min-width: 768px) and (max-width: 1132px) {
    .addr {
      top: 17%;
      left: 3%;
    }

    .frame2 {
      top: 41%;
      left: 23%;
    }
    .frame3 {
      bottom: 19%;
      right: -1%;
    }
  }

  @media (max-width: 768px) {
    /* first card */
    .addrContainer {
      flex-direction: column;
    }
    .addr {
      position: absolute;
      top: -11%;
      left: 34%;
      height: 31.71px;
    }
    .addr span {
      font: 400 23rem / 1.3em "Inter", sans-serif;
    }

    .frame2 {
      position: absolute;
      top: 36%;
      left: 51.5%;
    }

    .frame2 div {
      height: 31.71px;
      width: max-content;
    }

    .thespan {
      font: 400 23rem / 1.3em "Inter", sans-serif;
    }

    .frame3 {
      position: absolute;
      bottom: 9%;
      right: -3%;
      height: 31.71px;
      width: 136px;
    }

    .frame3 span {
      font: 400 23rem / 1.3em "Inter", sans-serif;
    }
    /* second card */
    .card2 {
      position: unset;
      align-items: unset;
      justify-content: flex-start;
    }
    .card2con {
      position: unset;
    }
    .seed {
      width: 100%;
      height: 40.25px;
    }
    .seed2 {
      width: 100%;
      height: 53.61px;
    }

    .seed span {
      font: 400 23rem / 1.3em "Inter", sans-serif;
    }
    .seed2 span {
      font: 400 23rem / 1.3em "Inter", sans-serif;
    }
    /* third card */
    .bottomText {
      font: 700 24rem / 1.3em "Inter", sans-serif;
      padding: 8px;
    }

    .fullnft {
      display: block;
    }
    .halfnft {
      display: none;
    }
    .topSec {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bottomSec {
      width: 100%;
      margin-top: 22px;
    }
    .bottomText {
      width: 100%;
    }

    .bottomSec img {
      width: 100%;
      height: auto;
      object-fit: scale-down;
    }
  }

  @media (max-width: 500px) {
    .frame2 {
      top: 46%;
      left: 45.5%;
    }
    .frame3 {
      bottom: -1%;
      right: -6%;
    }
  }

  @media (max-width: 400px) {
    .addr {
      top: -21%;
      left: 25%;
    }
    .frame2 {
      top: 41%;
      left: 36.5%;
    }
    .frame3 {
      bottom: 4%;
      right: -13%;
    }
  }

  @media (max-width: 350px) {
    .frame3 {
      bottom: -1%;
      right: -13%;
    }
  }
`;

export default Home;
