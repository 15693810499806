import { css } from "@emotion/css";
import Link from "next/link";
import BrandingText from "../../assets/Branding/BrandingText";
import DesignSystemNormalButton from "../../components/DesignSystem/DesignSystemNormalButton";

const FooterTaglineStyle = css`
  font: 400 24rem/1.3em "Inter", sans-serif;
`;

const FooterTextStyle = css`
  font: 400 17rem/1.3em "Inter", sans-serif;
  color: unset;
  text-decoration: unset;
`;

const LandingFooter: React.FC = () => {
  return (
    <footer
      className={css`
        label: LandingFooter;
        width: 1500rem;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 40rem 64px;
        box-sizing: border-box;

        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        gap: 20rem 50rem;

        & > * {
          flex: 1 0 0;
          min-width: 200px;
        }
      `}
    >
      <div
        className={css`
          label: LandingFooter-left;
          text-align: left;

          display: flex;
          flex-direction: column;
          gap: 20rem;
        `}
      >
        <div
          className={css`
            label: LandingFooter-left-tada;
            font-size: 48rem;
          `}
        >
          🎉
        </div>
        <div className={FooterTaglineStyle}>
          Mint Kudos, celebrate <br />
          work — together.
        </div>
        <div>
          <DesignSystemNormalButton
            theme="primary"
            target="_blank"
            href={BrandingText.DiscordInviteUrl}
            rel="noreferrer"
          >
            Join community &rarr;
          </DesignSystemNormalButton>
        </div>
      </div>
      <div
        className={css`
          label: LandingFooter-center;
          text-align: center;

          display: flex;
          justify-content: center;
          white-space: nowrap;
          gap: 48rem;
        `}
      >
        <a
          className={FooterTextStyle}
          target="_blank"
          href={BrandingText.TwitterUrl}
          rel="noreferrer"
        >
          Twitter
        </a>{" "}
        <a
          className={FooterTextStyle}
          target="_blank"
          href={BrandingText.DiscordInviteUrl}
          rel="noreferrer"
        >
          Discord
        </a>{" "}
        <Link href="#faq">
          <a className={FooterTextStyle}>FAQ</a>
        </Link>
      </div>
      <div
        className={css`
          label: LandingFooter-right;
          text-align: right;
        `}
      >
        <div className={FooterTextStyle}>Copyright © 2022</div>
      </div>
    </footer>
  );
};

export default LandingFooter;
