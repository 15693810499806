import DesignSystemButton from "./DesignSystemButton";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  theme: "secondary" | "primary" | "hidden";
  active?: boolean;
}

/**
 * @deprecated Use `<DesignSystemButton large />` instead.
 * Note that theme="secondary" has changed to theme="text".
 */
const DesignSystemLargeButton: React.FC<Props> = (props) => {
  let { theme, ...restProps } = props;
  return (
    <DesignSystemButton
      large
      theme={theme === "secondary" ? "text" : theme}
      {...restProps}
    />
  );
};

export default DesignSystemLargeButton;
