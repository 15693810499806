/* eslint-disable react/no-children-prop */
import { css } from "@emotion/css";
import DesignSystemColors from "./DesignSystemColors";

const DesignSystemLayout = {
  /**
   * @deprecated use `<LayoutRow evenSplit>`.
   */
  EqualColumns: (props: {
    gap?: string;
    itemMinWidth?: string;
    children: React.ReactElement[];
  }) => {
    return (
      <div
        children={props.children}
        className={css`
          label: DesignSystemLayout-EqualColumns;
          display: flex;
          flex-direction: row;
          gap: ${props.gap || "16rem"};
          flex-wrap: ${props.itemMinWidth ? "wrap" : "unset"};

          & > * {
            flex: 1 0 0;
            width: 0;
            min-width: ${props.itemMinWidth || "unset"};
          }
        `}
      />
    );
  },
  /**
   * @deprecated use `<LayoutRow>`.
   */
  Columns: (props: { children: React.ReactElement[] }) => {
    return (
      <div
        children={props.children}
        className={css`
          label: DesignSystemLayout-Columns;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
        `}
      />
    );
  },
  /**
   * @deprecated use `<LayoutChild>`.
   */
  FlexSpacer: (props: { grow?: number }) => {
    return (
      <div
        className={css`
          label: DesignSystemLayout-FlexSpacer;
          flex-basis: 0;
          flex-grow: ${props.grow ?? 1};
        `}
      />
    );
  },
  /**
   * @deprecated use `<LayoutChild>`, or use the `gap` property on `<LayoutRow>`
   * and `<LayoutCol>`.
   */
  Spacer: (props: { size: string }) => {
    return (
      <span
        className={css`
          label: DesignSystemLayout-Spacer;
          display: inline-block;
          flex-shrink: 0;
          height: ${props.size};
          width: ${props.size};
        `}
      />
    );
  },
  Hr: () => {
    return (
      <hr
        className={css`
          label: DesignSystemLayout-Hr;
          /* https://erikmartinjordan.com/style-hr-line-wavy */
          border: none;

          &:after {
            content: "∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿∿";
            letter-spacing: -0.16em;
            font-size: 35rem;

            display: block;
            font-weight: bold;
            margin: 0.5em 0 0;
            text-align: center;
            transform: scaleY(0.5);

            background: linear-gradient(to right, #b9e5cd, #b7d4f7);
            background-clip: text;
            text-fill-color: transparent;
          }
        `}
      />
    );
  },

  ControlBorderStyle: css`
    label: DesignSystemLayout-ControlBorderStyle;
    background: white; // On iOS, <select> and <input type=date> are gray.
    border-radius: 1em;
    border: 1px solid #88888888;
    box-shadow: inset 0 0 2rem 0 #88888888;
    margin: 0.3em 0;
    padding: 0.5em 1em;

    &::placeholder {
      color: ${DesignSystemColors.Placeholder};
    }
  `,

  ReadingWidthStyle: css`
    label: DesignSystemLayout-ReadingWidthStyle;
    box-sizing: border-box;
    width: 100%;
    max-width: 650rem;
    margin: 0 auto;
    padding: 5vh 5vw;
  `,
};

export default DesignSystemLayout;
