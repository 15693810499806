import { css, cx, keyframes } from "@emotion/css";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import BrandingText from "../../assets/Branding/BrandingText";
const ImgCalendar = "/images/branding/icon/IconCalendar.svg";
const ImgDiscord = "/images/branding/icon/IconDiscord.svg";
const ImgTwitter = "/images/branding/icon/IconTwitter.svg";
import DesignSystemLargeButton from "../../components/DesignSystem/DesignSystemLargeButton";
import DesignSystemLayout from "../../components/DesignSystem/DesignSystemLayout";
import DesignSystemNormalButton from "../../components/DesignSystem/DesignSystemNormalButton";
import DesignSystemText, {
  TextBody,
  TextH1,
  TextH2,
  TextLink,
  TextSecondary,
} from "../../components/DesignSystem/DesignSystemText";
import { useJwtAuth } from "../../services/auth/JwtAuthState";
import { useWallet } from "../../services/wallet/useWallet";
import { useLoginScreen } from "../Account/LoginScreenState";
const Ball = "/images/landing/Ball.png";
const Ball2 = "/images/landing/Ball2.png";
const Cross = "/images/landing/cross.png";
const Cube = "/images/landing/Cube.png";
const Cube2 = "/images/landing/Cube2.png";
const Cube3 = "/images/landing/Cube3.png";
const FullNft = "/images/landing/fullnft.png";
const HalfNft = "/images/landing/halfNFT.png";
const ImgBgGetInvolved = "/images/landing/ImgBgGetInvolved.jpg";
const ImgBgWhyKudos = "/images/landing/ImgBgWhyKudos.png";
import ImgFgMintKudosCelebrateWork from "../../../public/images/landing/ImgFgMintKudosCelebrateWork.png";
const Torus1 = "/images/landing/Torus1.png";
const Torus2 = "/images/landing/Torus2.png";
const Inp = "/images/landing/inp.png";
import LandingFooter from "./LandingFooter";
import { isTestnetEnv } from "../../config/env";
import Image from "../../components/Image/Image";
import KudosPreview from "../CreateKudos/KudosPreview";
import Media from "../../components/Media/Media";
import Button from "../../components/Button/Button";

const LandingPageNfts = [
  {
    src: "https://images.mintkudos.xyz/token/674.mp4",
    title: "Education certification",
    route: "https://mintkudos.xyz/community/alchemy?tab=Kudos&tokenId=674",
  },
  {
    src: "https://images.mintkudos.xyz/token/2351.mp4",
    title: "Completing a workshop",
    route: "https://mintkudos.xyz/community/wbw3?tab=Kudos&tokenId=2351",
  },
  {
    src: "https://images.mintkudos.xyz/token/2698.mp4",
    title: "Education access pass",
    route:
      "https://mintkudos.xyz/community/alchemyUniversity?tab=Kudos&tokenId=2698",
  },
  {
    src: "https://images.mintkudos.xyz/token/661.mp4",
    title: "Speaking at a conference",
    route: "https://mintkudos.xyz/community/adpList?tab=Kudos&tokenId=661",
  },

  {
    src: "https://images.mintkudos.xyz/token/2445.mp4",
    title: "Team shoutouts",
    route:
      "https://mintkudos.xyz/community/contributionlabs?tab=Kudos&tokenId=2445",
  },
  {
    src: "https://images.mintkudos.xyz/token/2880.png",
    title: "Welcoming a new teammate",
    route:
      "https://mintkudos.xyz/community/contributionlabs?tab=Kudos&tokenId=2445",
  },
  {
    src: "https://images.mintkudos.xyz/token/408.png",
    title: "Winning an award",
    route:
      "https://mintkudos.xyz/profile/0x559279f6c2acae72ee928b6502a4ac4b32a4db11?tab=Sent&tokenId=408",
  },
  {
    src: "https://images.mintkudos.xyz/token/2531.mp4",
    title: "Attending a community townhall",
    route: "https://mintkudos.xyz/community/wbw3?tab=Kudos&tokenId=2531",
  },
] as const;

const LandingSectionContentsStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 36rem;

  & > header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 52px;
    display: flex;
    align-items: center;

    color: #000000;
  }

  & > header.big {
    font-size: 48rem;
  }

  & > p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    max-width: 800px;
    /* Secondary */
    color: rgba(26, 26, 26, 0.5);
  }

  & > p > a {
    font-weight: 600;
    color: unset;
    text-decoration: unset;
  }
`;

const LandingSection: React.FC<{
  className?: string;
}> = (props) => (
  <section
    className={css`
      position: relative;
      min-height: min(800px, 110vmin);
      flex-shrink: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      padding: 0 7vw;
      ${props.className};

      @media (max-width: 768px) {
        & > header {
          font: 800 40rem / 1.3em "Inter", sans-serif !important;
        }
        & > p {
          font: 400 24rem / 1.3em "Inter", sans-serif !important;
          font-size: 28rem !important;
          padding: 0 20px;
        }

        & > div > header {
          font: 800 40rem / 1.3em "Inter", sans-serif !important;
        }
        & > div > p {
          font: 400 24rem / 1.3em "Inter", sans-serif !important;
          font-size: 28rem !important;
          padding: 0 20px;
        }
      }
    `}
  >
    {props.children}
  </section>
);

const LandingDetailsFadeIn = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

const LandingDetailsList: React.FC = (props) => (
  <div
    className={css`
      text-align: left;
      width: 700rem;

      & > details {
        margin: 36rem 20rem;
      }

      & > details:first-of-type {
        margin-top: 0;
      }

      & > details > summary {
        font: 700 24rem/1.3em "Inter", sans-serif;

        cursor: pointer;
        list-style: none;
        position: relative;
      }

      & > details > summary:focus {
        outline: 1px solid #00000022;
        outline-offset: 4rem;
      }

      & > details > summary::-webkit-details-marker {
        display: none;
      }

      & > details > summary::before {
        font: 200 24rem/1em "Inter", sans-serif;

        font-weight: 700;
        position: absolute;
        top: 0;
        left: -1.4em;
        content: "+";
        width: 1em;
        text-align: center;
        line-height: 1.2;
      }

      & > details[open] > summary::before {
        content: "–";
      }

      & > details > :not(summary) {
        animation: ${LandingDetailsFadeIn} 0.1s ease-out;
      }

      & > details > p {
        font: 400 17rem/1.3em "Inter", sans-serif;

        margin: 0.5em 0;
      }
    `}
  >
    {props.children}
  </div>
);

const LandingPage: React.FC<{}> = (props) => {
  const router = useRouter();
  const loginScreen = useLoginScreen();
  const { isAuthenticated } = useJwtAuth();
  const { disconnect, avatarUrl, username, addresses, displayName } =
    useWallet();
  const [startMinting, setStartMinting] = useState(false);

  const handleLogin = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!addresses[0] || !isAuthenticated) {
      setStartMinting(true);
      loginScreen.open();
      e.preventDefault();
    } else {
      router.push("/create");
    }
  };

  useEffect(() => {
    if (username && isAuthenticated && startMinting) {
      router.push("/create");
    }
  }, [username, isAuthenticated]);

  const textGradient =
    "!text-transparent bg-clip-text bg-gradient-to-r from-[#b9e5cd] to-blue-300";

  return (
    <main className="overflow-hidden">
      <section
        className={cx(
          "flex flex-col justify-center items-center mt-[160rem] mb-[300rem] px-5",
        )}
      >
        <TextH1 className="relative text-center  whitespace-nowrap leading-[80rem] !text-[64rem] md:leading-[100rem] md:!text-[80rem] lg:leading-[136rem] lg:!text-[120rem]">
          mint{" "}
          <span
            className={cx(
              "relative underline decoration-wavy decoration-[#b9e5cd] underline-offset-[8rem] decoration-2 md:underline-offset-[12rem] md:decoration-[3px] lg:underline-offset-[20rem] lg:decoration-[4px]",
              textGradient,
            )}
          >
            kudos
            <p
              className={cx(
                "absolute text-[20rem] top-[-16rem] right-[-60rem] md:right-[-100rem] md:text-[32rem] md:top-[-24rem] lg:text-[36rem] lg:right-[-108rem]",
                textGradient,
              )}
            >
              BETA
            </p>
          </span>
          <span className={cx(textGradient)}>,</span> <br /> celebrate community{" "}
        </TextH1>

        <div
          className={cx(
            "mt-8 mb-10 text-center",
            css`
              ${DesignSystemText.BodyStyle};
              font-size: 28rem;
              padding: 0 1em;
            `,
          )}
        >
          NFTs to celebrate your community’s achievements, participations, and
          contributions.
        </div>

        <DesignSystemLargeButton
          theme="primary"
          onClick={(e) => handleLogin(e)}
        >
          Create Kudos{" "}
          <span
            className={css`
              font-style: normal;
              font-weight: 700;
              font-size: 24rem;
              line-height: 32rem;
              margin-left: 8rem;
            `}
          >
            {" "}
            -&gt;{" "}
          </span>
        </DesignSystemLargeButton>

        {!isTestnetEnv() ? (
          <TextLink
            href="https://sandbox.mintkudos.xyz"
            target="_blank"
            className="mt-5"
          >
            Try me on Testnet!
          </TextLink>
        ) : (
          <></>
        )}
      </section>

      <section className="px-10">
        <div
          className={css`
            position: absolute;
            left: 8%;
            top: 31%;
            z-index: -1;

            @media (max-width: 768px) {
              display: none;
              left: 0%;
              top: 10%;
              img {
                height: 80px;
                width: 100%;
              }
            }
          `}
        >
          <img src={Cube3} alt="SVG as an image" />
        </div>
        <div
          className={css`
            position: absolute;
            left: 3%;
            top: 46%;
            z-index: -1;
            @media (max-width: 768px) {
              display: none;
              left: 0%;
              top: 47%;
              img {
                height: 65px;
                width: 100%;
              }
            }
          `}
        >
          <img src={Ball} alt="SVG as an image" />
        </div>
        <div
          className={css`
            position: absolute;
            right: 4%;
            bottom: 24%;
            z-index: -1;

            @media (max-width: 768px) {
              display: none;
              right: 0%;
              bottom: 50%;
              img {
                height: 85px;
                width: 100%;
              }
            }
          `}
        >
          <img src={Ball2} alt="SVG as an image" />
        </div>

        <div className={cx("flex flex-col justify-center items-center")}>
          <TextH2 className="!text-[40rem] md:!text-[40px]">
            Our partners
          </TextH2>
          <TextSecondary className="mb-3 mt-3 !text-[28rem]">
            Hundreds of communities including
          </TextSecondary>
          <div className="max-w-[1300px] mt-12 mb-[200rem]">
            <LogoMarquee></LogoMarquee>
          </div>
        </div>
      </section>

      <LandingSection
        className={css`
          padding: 0;
        `}
      >
        <TextH1 className="!text-[40rem] md:!text-[40px]">
          What’s a Kudos?
        </TextH1>
        <TextSecondary className="!text-[28rem] mt-3 mb-10">
          A Kudos is a digital token representing involvement within an
          organization.
        </TextSecondary>

        <div
          className={css`
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            margin-top: 20rem;
            padding: 0 20rem;
          `}
        >
          <div
            className={css`
              max-width: 1800rem;
              width: 100%;
              height: auto;
              margin: 0 auto;
              justify-content: center;
              align-items: center;
              display: flex;
              flex-wrap: wrap;
              row-gap: 30rem;
              column-gap: 48rem;

              @media (max-width: 1023px) {
                max-width: 756px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                place-items: center;
              }
            `}
          >
            {LandingPageNfts.map((nft, i) => (
              <div
                key={i}
                className={css`
                  display: flex;
                  flex-direction: column;
                  gap: 13.25px;
                  max-width: 400rem;
                  width: 100%;
                  flex: 1 0 21%;
                  height: auto;
                `}
              >
                <Button
                  theme="custom"
                  onClick={() => {
                    router.push(nft.route);
                  }}
                >
                  <KudosPreview
                    src={nft.src}
                    className={"!w-full !h-auto"}
                    controls={false}
                  />
                </Button>

                <TextSecondary className="!text-[20rem]">
                  {nft.title}
                </TextSecondary>
              </div>
            ))}
          </div>
        </div>
      </LandingSection>

      <LandingSection className={LandingSectionContentsStyle}>
        <div
          className={css`
            position: absolute;
            right: 10%;
            top: 24%;
            z-index: -1;
            @media (max-width: 768px) {
              right: 0;
              top: 17%;
              display: none;
            }
          `}
        >
          <Image
            src={Torus1}
            alt="SVG as an image"
            width="107px"
            height="107px"
            className={css`
              @media (max-width: 768px) {
                max-width: 80px;
                height: 80px;
                width: 100%;
              }
            `}
          />
        </div>

        <div
          className={css`
            position: absolute;
            left: 10%;
            bottom: 18%;
            z-index: -1;

            @media (max-width: 768px) {
              left: 0;
              bottom: 14%;
              display: none;
            }
          `}
        >
          <Image
            src={Torus2}
            alt="SVG as an image"
            width="184px"
            height="184px"
            className={css`
              @media (max-width: 768px) {
                max-width: 120px;
                height: 120px;
                width: 100%;
              }
            `}
          />
        </div>
        <header
          className={css`
            font-style: normal;
            font-weight: 800;
            font-size: 40px;
            line-height: 52px;

            display: flex;
            align-items: center;

            color: #000000;
          `}
        >
          Why use Kudos?
        </header>
        <p>
          Giving Kudos is a great way to recognize and reward amazing feats or
          projects within your community. They serve as a badge of honor that
          commemorates the pride and joy behind a contribution.
        </p>

        <p>
          Individuals can claim Kudos to build out an on-chain, org-verified,
          authentic history of their work.
        </p>

        <p className="!font-semibold !text-black">Yes, we cover gas!</p>
      </LandingSection>

      <LandingSection className={LandingSectionContentsStyle}>
        <header>How it works</header>

        <div
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 1000px;
            width: 100%;
            margin: 0 auto;
            flex-direction: column;
            gap: 34px;
            margin-bottom: 60px;
            @media (max-width: 768px) {
              max-width: 619px;
              width: 100%;
            }
          `}
        >
          {/* first card */}
          {Step1()}

          {/* second card */}
          <Step2 />
          {/* third card */}
          <Step3 />
        </div>
        <DesignSystemLargeButton
          theme="primary"
          target="_blank"
          href={BrandingText.DiscordInviteUrl}
        >
          {"Create Kudos ->"}
        </DesignSystemLargeButton>
      </LandingSection>

      <div id="faq"></div>

      <LandingSection
        className={css`
          ${LandingSectionContentsStyle};
          justify-content: start;
          min-height: 0;
          margin-top: 180rem;
        `}
      >
        <header>FAQ</header>

        <LandingDetailsList>
          <details>
            <summary>
              Do I need to get my Kudos approved before I can issue them?
            </summary>
            <p>
              Nope! Once you are onboarded as an admin, you can mint a Kudos for
              anyone, at any time, with any look! Unlike other products, we do
              not have a review process for the NFTs you give out to your
              community.
            </p>
          </details>

          <details>
            <summary>Really? You cover all the gas?</summary>
            <p>
              Yes we do! Our goal is to provide the best experience possible for
              creators and communities. We’ve decided to cover the gas so that
              users don’t have to worry about buying MATIC in order to use
              MintKudos.
            </p>
          </details>

          <details>
            <summary>
              I received a Kudos containing false information.
              <br />
              What should I do?
            </summary>
            <p>
              We encourage everyone to not claim an incorrect Kudos as it
              devalues the authenticity of your current collection and promotes
              bad actors. But it’s your wallet, your choice.
            </p>
          </details>

          <details>
            <summary>
              Why mint the contribution as a Soulbound
              <br />
              (non-transferable) token?
            </summary>
            <p>
              Imagine our world as a village with a big bulletin board that
              tracks all the things people have done. Minting your contribution
              into a Soulbound token is like adding a community-verified record
              of your work onto that board for everyone to admire, with a
              guarantee that this record wasn’t bought and can’t be sold.
            </p>
          </details>

          <details>
            <summary>Can I mint a Kudos for myself?</summary>
            <p>
              No teammates? No problem. Give yourself a Kudos for doing
              something awesome. After all that hard work, give yourself a pat
              on the back! If you were a part of a team, make sure to include
              yourself!
            </p>
          </details>

          <details>
            <summary>Which chain are you on?</summary>
            <p>
              Polygon! We’ve made it a top priority to make sure gas prices
              don’t become a hurdle to giving out Kudos. In the future we may
              add the option to bridge the token to other chains (such as
              Ethereum Mainnet).
            </p>
          </details>
        </LandingDetailsList>
      </LandingSection>

      <LandingSection>
        <div
          className={css`
            ${LandingSectionContentsStyle};
            border-radius: 64rem;
            background-image: url(${ImgBgGetInvolved});
            background-size: cover;
            width: 100%;
            max-width: 1487px;
            height: 538px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 15px;
            @media (max-width: 550px) {
              height: 340px;
            }

            a {
              color: black !important;
            }
          `}
        >
          <header className="big">
            How do I get involved
            <br />
            or give feedback?
          </header>
          <TextBody className="max-w-[980rem] !text-[20rem]">
            We’re on a mission to enable the Contributor Economy. Say hi to us
            on{" "}
            <a
              target="_blank"
              href={BrandingText.DiscordInviteUrl}
              rel="noreferrer"
              className="!font-semibold"
            >
              Discord
            </a>{" "}
            or follow our{" "}
            <a
              className="!font-semibold"
              target="_blank"
              href={BrandingText.TwitterUrl}
              rel="noreferrer"
            >
              Twitter!
            </a>{" "}
            Want to directly connect with us? Book some time with our team{" "}
            <a
              className="!font-semibold"
              target="_blank"
              href={BrandingText.CalendlyUrl}
              rel="noreferrer"
            >
              here
            </a>
            .
          </TextBody>
          <TextBody>
            <DesignSystemNormalButton
              theme="secondary"
              circular
              target="_blank"
              href={BrandingText.TwitterUrl}
            >
              <Image src={ImgTwitter} width="20rem" height="20rem" alt="" />
            </DesignSystemNormalButton>
            <DesignSystemLayout.Spacer size="20rem" />
            <DesignSystemNormalButton
              theme="secondary"
              circular
              target="_blank"
              href={BrandingText.DiscordInviteUrl}
            >
              <Image src={ImgDiscord} width="20rem" height="20rem" alt="" />
            </DesignSystemNormalButton>
            <DesignSystemLayout.Spacer size="20rem" />
            <DesignSystemNormalButton
              theme="secondary"
              circular
              target="_blank"
              href={BrandingText.CalendlyUrl}
            >
              <Image src={ImgCalendar} width="20rem" height="20rem" alt="" />{" "}
              &nbsp; Calendly
            </DesignSystemNormalButton>
          </TextBody>
        </div>
      </LandingSection>

      <LandingFooter />
    </main>
  );
};

export default LandingPage;
function Step2() {
  return (
    <div
      className={css`
        width: 100%;
        height: 272px;
        left: 364px;
        top: 4050px;
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
        border-radius: 32px;
        display: grid;
        grid-template-columns: 52.05% auto;
        @media (max-width: 768px) {
          padding: 30px;
          height: auto;
          max-height: 543px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding-right: 0px;
          overflow: hidden;
        }
      `}
    >
      <div
        className={css`
          width: 100%;
          height: 100%;
          border-top-left-radius: 32px;
          border-bottom-left-radius: 32px;
          padding-left: 59px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 12px;
          @media (max-width: 768px) {
            justify-content: flex-start;
            height: auto;
            padding: 0px;
          }
        `}
      >
        <div
          className={css`
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #000000;
            font-family: "Inter", sans-serif;
            @media (max-width: 768px) {
              font: 700 24rem / 1.3em "Inter", sans-serif;
            }
          `}
        >
          Step 2: Send the Kudos
        </div>
        <div
          className={css`
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: rgba(26, 26, 26, 0.5);
            text-align: left;
            font-family: "Inter", sans-serif;
            @media (max-width: 768px) {
              font: 400 17rem / 1.3em "Inter", sans-serif;
            }
          `}
        >
          Send the Kudos by specifiying the recipients, tagging them on Discord,
          giving them a link, or generating a QR code.
        </div>
      </div>

      <div
        className={css`
          height: 100%;
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;
          overflow: hidden;

          @media (max-width: 768px) {
            overflow: unset;
            height: 116px;
          }
        `}
      >
        <div className="addrContainer">
          <div className="addr">
            <span>0x98717d0saf70218r09rasdf0a882973</span>
          </div>
          <div className="frame2">
            <div>
              <span className="thespan">darthvader.eth</span>
            </div>
            <div>
              <span className="thespan">hansolo.eth</span>
            </div>
          </div>

          <div className="frame3">
            <span>kyloren.eth</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function Step1() {
  return (
    <div
      className={css`
        width: 100%;
        height: 272px;
        left: 364px;
        top: 4050px;
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
        border-radius: 32px;
        display: grid;
        grid-template-columns: 52.05% auto;
        @media (max-width: 768px) {
          padding: 30px;
          height: auto;
          max-height: 543px;
          display: flex;
          flex-direction: column;
          gap: 45px;
          padding-right: 0px;
          overflow: hidden;
        }
      `}
    >
      <div
        className={css`
          max-width: 461px;
          width: 100%;
          height: 100%;
          border-top-left-radius: 32px;
          border-bottom-left-radius: 32px;
          padding-left: 59px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 12px;
          @media (max-width: 768px) {
            justify-content: flex-start;
            height: auto;
            padding: 0px;
          }
        `}
      >
        <div
          className={css`
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #000000;
            text-align: left;
            font-family: "Inter", sans-serif;
            @media (max-width: 768px) {
              font: 700 24rem / 1.3em "Inter", sans-serif;
            }
          `}
        >
          Step 1: Create the Kudos
        </div>
        <div
          className={css`
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: rgba(26, 26, 26, 0.5);
            text-align: left;
            font-family: "Inter", sans-serif;
            @media (max-width: 768px) {
              font: 400 17rem / 1.3em "Inter", sans-serif;
            }
          `}
        >
          Fill in the details of the Kudos you’re creating. We use these details
          to generate a Kudos that uniquely the achievement you’re celebrating.
        </div>
      </div>

      <div
        className={css`
          height: 100%;
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;
          overflow: hidden;
          @media (max-width: 768px) {
            overflow: unset;
            position: relative;
            right: -10px;
          }
        `}
      >
        <div className="card2">
          <div className="card2con">
            <div className="seed">
              <span>Raised $500k to fund amazing web3 project woo</span>
            </div>
            <div className="seed2">
              <span>
                Crazy amazing effort by this team, two week hustle Lorem ipsum
                dolor sit ❤️ via mintkudos.xyz
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Step3() {
  return (
    <div
      className={css`
        width: 100%;
        height: 272px;
        left: 364px;
        top: 4050px;
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
        border-radius: 32px;
        display: grid;
        grid-template-columns: 52.05% auto;
        @media (max-width: 768px) {
          padding: 30px;
          height: auto;
          max-height: 543px;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      `}
    >
      <div
        className={css`
          max-width: 461px;
          width: 100%;
          height: 100%;
          border-top-left-radius: 32px;
          border-bottom-left-radius: 32px;
          padding-left: 59px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 12px;
          @media (max-width: 768px) {
            justify-content: flex-start;
            height: auto;
            padding: 0;
          }
        `}
      >
        <div
          className={css`
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            color: #000000;
            font-family: "Inter", sans-serif;
            @media (max-width: 768px) {
              font: 700 24rem / 1.3em "Inter", sans-serif;
            }
          `}
        >
          Step 3: Claim the Kudos
        </div>
        <div
          className={css`
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 22px;
            color: rgba(26, 26, 26, 0.5);
            text-align: left;
            font-family: "Inter", sans-serif;
            @media (max-width: 768px) {
              font: 400 17rem / 1.3em "Inter", sans-serif;
            }
          `}
        >
          Others can mint the token on our site and celebrate their achievement
          with you!
        </div>
      </div>

      <div
        className={css`
          height: 100%;
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;
          overflow: hidden;
          @media (max-width: 768px) {
            overflow: unset;
          }
        `}
      >
        <div className="card3">
          <div className="card3">
            <div className="topSec">
              <Image
                src={FullNft}
                alt="Kudos"
                className={cx(
                  "fullnft",
                  css`
                    top: -160px !important;

                    @media (max-width: 760px) {
                      top: 0 !important;
                    }
                  `,
                )}
                height="200rem"
                width="200rem"
              />
            </div>

            <div className="bottomSec">
              <div className="bottomText">
                Share this link with your teammates so they can claim their
                kudos
              </div>
              <Image
                src={Inp}
                alt="Input Image"
                width="300rem"
                height="30rem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const LogoMarquee = () => {
  const baseUrl = "/images/landing";

  const logos = [
    `${baseUrl}/adplist-logo.svg`,
    `${baseUrl}/alchemy-logo.svg`,
    `${baseUrl}/bankless-dao-logo.svg`,
    `${baseUrl}/learn-web3-logo.svg`,
    `${baseUrl}/0x-logo.svg`,
    `${baseUrl}/polygon-logo.svg`,
    `${baseUrl}/shefi-logo.svg`,
    `${baseUrl}/women-build-web3-logo.svg`,
    `${baseUrl}/wonderverse-logo.svg`,
    `${baseUrl}/developer-dao-logo.svg`,
    `${baseUrl}/scaffold-eth-logo.svg`,
    `${baseUrl}/tally-logo.svg`,
    `${baseUrl}/threshold-logo.svg`,
  ];

  const ticker = keyframes`
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-100%);
    }
  `;

  const animationTime = "30s";
  const gap = "gap-x-[100rem]";

  const mappedLogos = logos.map((logo) => {
    return (
      <>
        <figure className="w-[160rem] h-[80rem] relative whitespace-nowrap">
          <Image src={logo} alt="logo" layout="fill" objectFit="contain" />;
        </figure>
      </>
    );
  });

  return (
    <>
      <div className={cx("overflow-x-hidden flex relative", gap)}>
        <div
          className={cx(
            `w-[${200}px] z-10`,
            "h-full absolute left-0 opacity-80 bg-gradient-to-r from-white to-transparent",
          )}
        />
        <div
          className={cx(
            "inline-flex",
            gap,
            css`
              animation: ${ticker} ${animationTime} linear infinite;
            `,
          )}
        >
          {mappedLogos}
        </div>
        <div
          className={cx(
            "inline-flex",
            gap,
            css`
              animation: ${ticker} ${animationTime} linear infinite;
            `,
          )}
        >
          {mappedLogos}
        </div>

        <div
          className={cx(
            `w-[${200}px]`,
            "h-full absolute right-0 opacity-80 bg-gradient-to-r from-transparent to-white",
          )}
        />
      </div>
    </>
  );
};
