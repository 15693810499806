import DesignSystemButton from "./DesignSystemButton";

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  theme: "text" | "secondary" | "primary" | [string, string];
  active?: boolean;
  circular?: boolean;
}

/**
 * @deprecated Use `<DesignSystemButton />` instead.
 */
const DesignSystemNormalButton: React.FC<Props> = (props) => {
  return <DesignSystemButton {...props} />;
};

export default DesignSystemNormalButton;
