import Image from "../Image/Image";
import { assetIsGif, assetIsVideo } from "../../utils/asset";

interface MediaProps {
  src: string;
  isVideo?: boolean;
  className?: string;
  autoplay?: boolean;
  controls?: boolean;
  muted?: boolean;
  loop?: boolean;
  height: number;
  width: number;
  priority?: boolean;
  playsInline?: boolean;
}

const Media = ({
  src,
  isVideo,
  className,
  autoplay,
  controls,
  muted,
  loop,
  height,
  width,
  priority,
  playsInline,
}: MediaProps) => {
  return src ? (
    <>
      {isVideo || assetIsVideo(src) ? (
        <video
          src={src}
          className={className}
          controls={controls}
          autoPlay={autoplay}
          muted={muted}
          loop={loop}
          playsInline={playsInline}
        ></video>
      ) : (
        <Image
          height={height || 400}
          width={width || 400}
          placeholder="blur"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            shimmer(height, width),
          )}`}
          src={src}
          alt=""
          className={className}
          priority={priority}
        />
      )}
    </>
  ) : (
    <></>
  );
};

const shimmer = (w: number | string, h: number | string) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#E8E8E8" offset="20%" />
      <stop stop-color="#F5F5F5" offset="50%" />
      <stop stop-color="#E8E8E8" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#E8E8E8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

Media.defaultProps = {
  autoplay: true,
  controls: true,
  muted: true,
  loop: true,
  isVideo: false,
  playsInline: true,
  height: 400,
  width: 400,
};

export default Media;
